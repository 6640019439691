#AboutIndex {
  background-color: rgb(255, 255, 255)
}


#AboutIndex .topLinkPageMobile{
  display: none;
}

#AboutIndex .aboutMobile{
  display: none;
}


@media (max-width: 767px){

  #AboutIndex .aboutMobile {
    display: block;
  }

  #AboutIndex .aboutWeb {
    display: none;
  }
  #AboutIndex .topLinkPageMobile{
    display:block;
  }
  
  #AboutIndex .topLinkPageWeb{
    display: none;
  }
 

 
  #AboutIndex .aboutMobile{
    display: block;
  }

}


#aboutSideList {
 
  position: sticky;
  z-index: 2;
  bottom: 0px;
  top: 90px;
  
}

#aboutSideList .advertisement {
display: flex;
flex-wrap: nowrap;
width: 8rem;
height: 180px;
background-color: indianred;
text-align: center;
padding-top: 3rem;
padding-left: 2rem;
margin-bottom: 10px;
color:rgb(255, 255, 255);
font-family: 'Noto Sans KR'
}

@media (max-width:770px){
#aboutSideList {
display: none;
}
.colSideList {
  display: none;
}

}


#aboutSideList .rowSideList li{
  list-style: none;
  line-height: 1.9rem;
  padding: 0 5px;
  font-size: 14px;
  color:rgb(10, 10, 10)
}

#aboutSideList .rowSideList .active li{
  display: flex;
  flex-wrap: nowrap;
  font-weight: 600;
  color: rgb(235, 18, 18);
  background-color: transparent;
  border-left: 4mm ridge rgba(194, 17, 55, 0.8); 
  box-shadow: none;
 }





#aboutCounterComponent .colCounterComponent {
  margin-top: 4rem;
}


#aboutCounterComponent .rowCounterComponent {
  height: auto;
  background-color: rgb(255, 255, 255);
  justify-content: center;
  align-items: center;

}

#aboutCounterComponent .rowInputSample {
  height: auto;
  background-color: rgb(255, 255, 255);
  justify-content: center;
  align-items: center;
  color: white;
  padding: 0 20px;

}

#aboutCounterComponent .rowInputSample2 {
  height: auto;
  background-color: rgb(255, 255, 255);
  justify-content: center;
  align-items: center;
  color: white;
    

}

#aboutCounterComponent .rowUserList {
  height: auto;
  background-color: rgb(255, 255, 255);
  justify-content: center;
  align-items: center;
  color: white;
}

#aboutCounterComponent .rowCreateUser {
  height: auto;
  background-color: rgb(255, 255, 255);
  justify-content: center;
  align-items: center;
  color: white;
}

#aboutCounterComponent .rowIntroduce {
  height: auto;
  background-color: rgb(255, 255, 255);
  justify-content: center;
  align-items: center;
  color: white;
}

#aboutCounterComponent .rowColGridSystem {
  display: grid;
  height: auto;
  background-color: rgb(255, 255, 255);

  color: rgb(172, 151, 151);
}



#aboutCounterComponent .topIconLink{
align-items: center;
justify-content: center;
text-align: center;
}



/* footerContainer css */
.footerBg{
  background-color: #000000;
}

.KstarCompPageContainer{
  max-width: 1600px !important;
}

