.countsort{
	position : relative;
	width : 100%;
	height : 0;
	padding-bottom : 56.25%;
}

.video{
	position : absolute;
	top : 0;
	left : 0;
	width : 100%;
	height : 100%;
}

#tabs-components .pills-secondary .nav-link.active {
  background-color: #A32B2A;
  box-shadow: none;
}

#Mobile_ver{
  display: none;
}

#Contact {
  background-color: #fff
}

#Contact .topLinkPageMobile{
  display: none;
}



#Contact .contactMobile{
  display: none;
}
@media (max-width: 767px) {
  #Contact .contactMobile{
    display: block;
  }

  #Contact .contactWeb{
    display: none;
  }
  #Contact .topLinkPageMobile{
    display:block;
  }
  
  #Contact .topLinkPageWeb{
    display: none;
  }
}



#Contact_1 .view {
  height: 40vh !important;
  background-repeat: no-repeat;
  background-color: black;
  background-size: cover;
  background-image: url(https://tritech.s3.ap-northeast-2.amazonaws.com/homepage_img/new-york-top-bg.png);
}

#Contact_1 .h-100 {
  background-color: none;
  background-size: cover;
  position: relative;
  top: 20em;
  left: 0em;
  text-align: center;
  font-size: 0.25em;
  background-repeat: no-repeat;
}

#DeskTop_ver .cleanEnergy h5{
  font-weight: bold;

}

.ict_tech ul{
  text-align: left!important;
  }

.ict_tech .title{
  color:rgb(202, 24, 24);
  font-weight: 900 !important;
}

.ict_tech .title_2{
  color:rgb(76, 130, 247);
  font-weight: bold !important;
}


@media (min-width: 990px){


  #Contact_1 .h-100 {
    
    background-color: none;
    background-size: cover;
    position: relative;
    top: 20em;
    left: 8em;
    text-align: center;
    font-size: 0.3em;
    background-repeat: no-repeat;
  }
  
  }

#Contact_1 .display-3{
 color: rgb(126, 121, 121);
 
}

#Contact_1 .btn-primary.dropdown-toggle {
  color: rgb(197, 197, 197);
  position: absolute;
  left: 0em;
  background-color: transparent!important;
  box-shadow: none;
  font-size: 5em;
  ;
}
#Contact_1 .btn-primary.dropdown-toggle:hover {
  color: white;
}

#Contact_1 .dropdown-menu {
  font-weight: 700;
  float: left;
  min-width: 10rem;
  padding: 1.2rem 0;
  margin: .125rem 0 0;
  font-size: 1.5rem;
  color: #212529;
  text-align: right;
  list-style: none;
  background-color: transparent;
  background-clip: padding-box;
  border: 0px solid rgba(0,0,0,.15);
  border-radius: .25rem;
  transition: 0.4s;
}

#Contact_1 .row-menu {
  display: inline;
}

#Contact_1 .dropdown-item {
  display: block;
  width: 100%;
  padding: .25rem 1.5rem;
  clear: both;
  font-weight: 400;
  font-size: 1em;
  color:  rgb(197, 197, 197);
 
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
  
}

#Contact_1 .dropdown-item:hover {
  box-shadow: none;
}

#Contact_sn .display-3 {
  display: none;
}
#Contact_sn .mt-5 {
  display: none;
}

#tab-atg .tab-link{
  font-weight: 700;
}





@media (max-width:1000px){
   
  #DeskTop_ver{
    display: none;
  }

  #Mobile_ver{
    display: inline;
  }

  #Contact_1 .h-100{
    text-align: center;
    font-size: 0.25em;
  }
  #Contact_1 .navbar-nav {
    position: absolute;
    top: 5em;
    display: -webkit-inline-box;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
    
}
#Contact_1 a {
  font-weight: 500;
  color: white;
 }


 #Contact_1 img{
  vertical-align: middle;
  border-style: none;
  width: 150px;
} 


 #Contact_sn .display-3 {
   position: relative;
   top: 0em;
   display: inline;
   font-size: 9em;
   color: #ffffff
  }


 #Contact_sn .mt-5 {
  display: inline;
  position: relative;
  top: 25em;
  text-align: center;
  text-shadow: 1px 1px 1px rgb(126, 126, 126);;
  color: #ffffff;
}


}


@media (max-width:700px) {

#Contact_sn .display-3 {
    position: relative;
    top: 0em;
    display: inline;
    font-size: 9em;
    color: #ffffff
  }
#Contact_sn .mt-5 {
  display: inline;
  position: relative;
  top: 55em;
  text-align: center;
  text-shadow: 1px 1px 1px rgb(126, 126, 126);;
  color: #ffffff;
}



#Contact_1 .btn {
  display: none;
  font-weight: 400;
}
  #Contact_1 .view {
      height: 40vh !important;
     
      background-repeat: no-repeat;
      background-size: cover;
   }


   #Contact_1 .d-flex_app {
     display: inline;
     position: absolute;
     top: 37em;
     color: rgb(19, 19, 19);
     background-color: rgb(255, 255, 255);
        }

 #Contact_1 .text-uppercase {
    font-size: 1rem;
    text-align: center;
    
   }
   #Contact_1 .view .mb-5, .my-5 {
    margin-bottom: 1rem!important;
    margin-top: 1rem!important;
  }
}


/* footerContainer css */
.footerBg{
  background-color: #000000;
}
