/* 헤더 탑 */
/* 
 .input-group.md-form.form-sm.form-1 input {
  border: 1px solid #bdbdbd;
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
} */
#top-menu1 {
  color: rgb(255, 255, 255);
  background-color: rgb(30, 57, 92);
  font-weight: 500;
  padding-top: 5px;
}
#top-menu1 .telItem {
  padding-top: 2px;
  padding-bottom: 15px;
  text-align: center;
}
#top-menu1 .textRight {
  text-align: center;
}
#top-menu .navItemLogo img {
  /* 바 왼쪽 설정 */
  width: 100%;
}
#top-menu .barRightSpace {
  /* 바 오른쪽 설정 
#top-menu .navbar-nav>li{ padding = 50px } 값을 고려해 수치 설정*/
  /* width: 100px; */
  display: flex;
  align-items: center;
  justify-content: center;
}
#top-menu .menuBtn {
  display: inline;
}
#top-menu {
  color: rgb(0, 0, 0);
  background-color: rgb(255, 255, 255);
  font-weight: 500;
  padding-top: 5px;
}
#top-menu .title_logo {
  padding-left: 5 PX;
  padding-top: 7px;
  font-size: 20px;
  font-weight: 500;
  font-family: "Sarpanch", sans-serif;
  /* transform: rotate(-20deg); */
  /* letter-spacing: inherit; */
  /* size: letter; */
}
#top-menu .fa-bars {
  padding-top: 10px;
}
.title_top_1 {
  display: block;
  left: 55px;
  top: 55px;
  position: absolute;
  height: 55px;
  width: 55px;
}
#top-menu .navbar-nav > li {
  padding-right: 0;
  padding-left: 0;
}
#headerId .side-nav {
  display: block;
  /* margin: 0px 20px 0px;  */
}

/* .logo-wrapper img {
   height: auto;
  padding: 15% 50px 20% 80px;
} */

#top-menu .d-md-inline {
  padding-right: 1rem;
}
/* #top-menu .Ripple-parent {
position: relative;
overflow: hidden;
cursor: pointer;
padding-top: 0px;
padding-right: 1.1em;
left: 0;
} */
#top-menu-id .dropdown:hover > .dropdown-menu {
  display: block;
  background-color: #ffffff;
}
#top-menu-id .dropdown > .btn:active {
  /*Without this, clicking will make it sticky*/
  pointer-events: none;
}
#dropdown-top-menu .btn {
  margin-top: -2rem;
  color: inherit;
  text-transform: uppercase;
  word-wrap: break-word;
  white-space: normal;
  cursor: pointer;
  border: 0;
  border-radius: 0.125rem;
  /* box-shadow: 0 2px 5px 0 rgba(0,0,0,0.16), 0 2px 10px 0 rgba(0,0,0,0.12); */
  box-shadow: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  /* padding: .84rem 2.14rem; */
  padding: 10px;
  font-weight: 400;
  font-size: 1.3rem;
}
/* Overline From Left */
#top-menu-id .Ripple-parent {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  position: relative;
  overflow: hidden;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
  font-size: 19px;
  color: rgb(30, 57, 92);
}
#top-menu-id .Ripple-parent:before {
  content: "";
  position: absolute;
  z-index: -1;
  left: 0;
  right: 100%;
  bottom: 0;
  background: #2098d1;
  height: 4px;
  -webkit-transition-property: right;
  transition-property: right;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
  margin-bottom: 4px;
  padding-left: 0;
}
#top-menu-id .Ripple-parent:hover:before,
#top-menu-id .nav-link .Ripple-parent:focus:before,
#top-menu-id .nav-link .Ripple-parent:active:before {
  /* left: 0; */
  right: 0;
}
#top-menu-id .nav-link {
  padding-right: 0;
  padding-left: 0;
}
/* dropdown-item */
#top-menu-id .Ripple-parent .dropdown-item {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  position: relative;
  overflow: hidden;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
  font-size: 18px;
  color: rgb(30, 57, 92);
  font-weight: 400;
}
/* #top-menu-id  .Ripple-parent:before {
content: "";
position: absolute;
z-index: -1;
left: 0;
right: 100%;
bottom: 0;
background: #2098D1;
height: 4px;
-webkit-transition-property: right;
transition-property: right;
-webkit-transition-duration: 0.3s;
transition-duration: 0.3s;
-webkit-transition-timing-function: ease-out;
transition-timing-function: ease-out;
margin-bottom :4px;
padding-left: 0px;
} */

#top-menu-id .Ripple-parent:before {
  content: "";
  position: absolute;
  z-index: -1;
  left: 0;
  right: 100%;
  bottom: 0;
  background: none;
  height: 4px;
  -webkit-transition-property: right;
  transition-property: right;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
  margin-bottom: 4px;
  padding-left: 0;
}
#top-menu-id .Ripple-parent:hover:before,
#top-menu-id .nav-link .Ripple-parent:focus:before,
#top-menu-id .nav-link .Ripple-parent:active:before {
  /* left: 0; */
  right: 0;
}
#top-menu-id .nav-link {
  padding-right: rem;
  padding-left: 0;
}
#top-menu .nav {
  font-weight: 900;
}

/* dropdown-top-menu 시작 */
#dropdown-top-menu.nav-item {
  padding-left: 0;
  padding-right: 60px;
}

#dropdown-top-menu .btn-primary {
  background-color: transparent !important;
}
#dropdown-top-menu .dropdown-menu a {
  padding: 10px;
  font-size: 0.9rem;
  font-weight: bold;
  color: rgb(30, 57, 92);
  background-color: none;
}
#dropdown-top-menu .dropdown-menu {
  border-top: solid rgb(30, 57, 92) 2px !important;
  background-color: rgb(255, 255, 255);
  border-radius: 0;
  width: 10rem;
  border: 0 solid darkgray;
  margin: 0;
  animation: fadeIn;
  animation-duration: 0.4s;
}
#dropdown-top-menu .dropdown-menu .menu-bar {
  display: flex;
}
/* 헤드 메뉴 리스트 그룹 */
.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  background-color: transparent;
  border: none;
}
/* 헤더 사이드 */
#sides-nav .fa-angle-down:before {
  content: "";
}
.mdb-skin .side-nav .collapsible li .collapsible-header {
  color: #fff;
  transition: all 0.3s linear;
  font-weight: 600;
}
.mdb-skin .side-nav .sidenav-bg:after,
.mdb-skin .side-nav .sidenav-bg.mask-strong:after {
  background: rgb(22, 22, 22);
}
#title .side-nav a {
  color: white;
  display: block;
  height: 1em;
  padding-top: 20px;
  padding-left: 20px;
  line-height: 5px;
}
#title .d-md-inline {
  display: inline !important;
  font-size: 2em;
}
.mdb-skin .side-nav .logo-wrapper img {
  height: auto;
  padding: auto 50px auto;
  margin: 0;
}
.side-nav .collapsible-body a {
  background-color: #18181a;
}
.side-nav .collapsible a {
  font-size: 1rem;
}
.side-nav .collapsible li a:hover {
  background-color: rgb(70, 70, 70);
}
#top-menu-id a {
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
  font-size: 15px;
  color: #fff;
}
#top-menu-id .navbar-nav > li {
  padding-top: 5px;
  padding-bottom: 1px;
  padding-right: 3em;
}
#drop-down .nav-right {
  position: relative;
  top: 0.3em;
}
#drop-down .d-none {
  color: rgb(30, 57, 92);
  font-weight: 700;
}
.navbar .dropdown-menu {
  min-width: auto;
  position: absolute;
  background-color: #114f9a;
}
.navbar .dropdown-menu.show {
  display: block;
  /* background-color: rgba(17, 79, 154, 0.7); */
  background-color: rgba(255, 255, 255, 0.9);
  border: 0;
}
.mdb-skin .navbar .navbar-nav .nav-item .dropdown-menu a:hover,
.mdb-skin .navbar .navbar-nav .nav-item .dropdown-menu a:focus,
.mdb-skin .navbar .navbar-nav .nav-item .dropdown-menu a:active {
  background-color: #114f9a;
}

div.toggle-div {
  display: inline-block;
  position: relative;
  left: 47%;
  cursor: pointer;
}
div.toggle-div .toggle-container {
  width: 50px;
  height: 24px;
  border-radius: 30px;
  background-color: rgb(51, 178, 255);
}
div.toggle-div .toggle-container.toggle-checked {
  background-color: rgb(0, 200, 102);
  transition: 0.5s;
}
div.toggle-div .toggle-circle {
  position: absolute;
  top: 1px;
  left: 1px;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  text-align: center;
  font-size: 0.8em;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(255, 254, 255);
  transition: 0.5s;
}
div.toggle-div .toggle-circle.toggle-checked {
  left: 27px;
  transition: 0.5s;
}

/* ------------------------------------- */
@media (max-width: 767px) {
  #top-menu .menuBtn {
    display: inline;
  }
  #top-menu .navItemLogo {
    display: none;
  }
  #top-menu-id {
    display: none;
  }
  #top-menu .Ripple-parent {
    position: relative;
    overflow: hidden;
    cursor: pointer;
    padding-top: 4px;
    padding-right: 0;
    left: 0;
  }
  #top-menu-id {
    margin-left: 15px;
    min-width: 500px;
  }
  /* #top-menu .menuBtn {
    display: none;
  } */
  #top-menu .navbar-nav > li {
    padding-right: 20px;
    padding-left: 0;
  }
}

@media (min-width: 768px) and (max-width: 1279px) {
  #top-menu .menuBtn {
    display: none;
  }
  #top-menu .Ripple-parent {
    position: relative;
    overflow: hidden;
    cursor: pointer;
    padding-top: 4px;
    padding-right: 0;
    left: 0;
  }
  #top-menu .navbar-nav > li {
    padding-right: 20px;
    padding-left: 0;
  }
  /*메뉴바 중앙으로 위치하도록 여백조절 #top-menu .navbar-nav>li{ padding } 값에 영향받음*/
  #top-menu-id {
    margin-left: 15px;
  }
  #dropdown-top-menu.nav-item {
    padding-left: 0;
    padding-right: 15px;
  }
}

@media (min-width: 1280px) {
  #top-menu .menuBtn {
    display: none;
  }
  #top-menu .Ripple-parent {
    position: relative;
    overflow: hidden;
    cursor: pointer;
    padding-top: 4px;
    padding-right: 0;
    left: 0;
  }
  #top-menu .navbar-nav > li {
    padding-right: 50px;
    padding-left: 2px;
  }
  /*메뉴바 중앙으로 위치하도록 여백조절 #top-menu .navbar-nav>li{ padding } 값에 영향받음*/
  #top-menu-id {
    /* margin-left: 115px; */
  }
}
