#kstarCard #card {
    
    width: 100%;
    margin: 0 auto;
    text-align: center;
    --display: flex;
  justify-content: center;
}
#kstarCard #card #row {
    width: 100%;
    float: left;
    display: block;
}
@media all and (min-width:768px)
{
#kstarCard #card img {
    
    width : 480px;
    margin: 120px;
}
}

