#cleanProjects {
  margin-top: 5rem!important;
  word-break: keep-all;
}
#cleanProjects h2{
  color:#007D14;
  
}

#cleanProjects p{
  font-weight: bold;
  color: #565656;
}
#cleanProjects .CleanHrSize{
  border: 1px solid #007D14;
}

#cleanProjects .colTextpt{
   justify-content: center;
   padding-left: 1em;
}

#cleanSectionID .rowText1Mb{
  display: none;
}

#cleanSectionID .rowText1{
 justify-content: center;
 
}
#cleanSection2Id .colTextpt{
  justify-content: center;
  padding-left: 1em;
  letter-spacing: 0.9em;
}

#cleanProjects .colTextpt2{
 font-weight: 400;
 text-align: center;
 font-size: 35px;
 color: #007D14;
 padding-left: 1em;
}

#cleanSection2Id .colTextpt2{
  font-weight: 400;
  text-align: center;
  font-size: 35px;
  color: #007D14;
  padding-left: 0.5em;
 }

#cleanProjects .rowText1{
 font-size: 1em;
 margin-bottom: 5rem;
}

#cleanProjects .rowCleanProjectsMobile{
  display: none;
}

#cleanSection2Id .rowCleanProjectsMobile{
  display: none;        
}

#cleanSection2Id {
  background-color: #F5F5F5;
}

#cleanSection2Id .colCleanProjects{
  line-height: 2;
}

#cleanSection2Id .colCleanProjectsMb{
  display: none;
}




#cleanSection3Id .colTextpt2{
  font-weight: 400;
  text-align: center;
  font-size: 35px;
  color: #007D14;
  padding-left: 0.5em;
  letter-spacing: 0.7rem;
  
 }
 #cleanSection3Id .colTextpt{
  justify-content: center;
  padding-left: 1em;
  letter-spacing: 0.9em;
}

#cleanSection3Id .rowCleanProjectsMobile{
  display: none;        
}

#cleanSection3Id {
  background-color: #ffffff;
}

#cleanSection3Id .colCleanProjects{
  line-height: 2;
  font-weight: 500;
}

#cleanSection3Id .colCleanProjectsMb{
  display: none;
}





#cleanSection4Id .colTextpt2{
  font-weight: 400;
  text-align: center;
  font-size: 35px;
  color: #007D14;
  letter-spacing: 1rem;
  
 }
 #cleanSection4Id .colTextpt{
  justify-content: center;
  padding-left: 1em;
  letter-spacing: 0.9em;
}



#cleanSection4Id .rowCleanProjectsMobile{
  display: none;        
}

#cleanSection4Id {
  background-color: #F5F5F5;
}

#cleanSection4Id .colCleanProjects{
  line-height: 2;
  font-weight: 500;
}

#cleanSection4Id .colCleanProjectsMb{
  display: none;
}

#cleanSection4Id .containSection4{
  margin-bottom: 4rem;  
}

#cleanSection4Id .containSection4 h5{
  line-height: 4;
}

#cleanSection4Id .containSection4Mb{
  display: none;
}


#cleanSection5Id .colSection5 hr{
 border-color: #007D14; 
}


#cleanSection5Id .colSection5{
  color: #007D14; 
  font-size: 1.5em;
  font-weight: 500;
  letter-spacing: 1em;
  text-align: center;
  line-height: 1;
}

#cleanSection5Id .colSectionImg5{
  padding-right: 0;
  padding-left: 0;
}

#cleanSection5IdMb {
  display: none;
}




@media (max-width: 992px){
  #cleanProjects .rowCleanProjectsMobile{
    display: block;        
  }
  #cleanProjects .colTextpt{
    font-size: 0.8em;
    letter-spacing: 0.6em; 
  }
  #cleanProjects .colTextpt2{
    font-size: 1.5em; 
    letter-spacing: 0.3em; 
  }


  #cleanProjects .rowCleanProjectsWeb{
    display: none;
  }
  #cleanSectionID .colCleanImg img{
    width: 19em;
  }

  #cleanSectionID .rowText1{
    display: none;
  }
  #cleanSectionID .rowText1Mb{
   display: block;
   font-size: .8em;
   text-align: center;
    }

  #cleanSection2Id .rowCleanProjectsMobile{
    display: block;        
  }

  #cleanSection2Id .colTextpt{
   letter-spacing: .5em;   
   
  }

  #cleanSection2Id .colTextpt2{
    font-size: 1.5em;    
   }

  #cleanSection2Id .rowCleanProjectsWeb{
    display: none;        
  }

  #cleanSection2Id .colCleanProjects{
    display: none;
  }

  #cleanSection2Id .colCleanProjectsMb{
    display: block;
    font-size: small;

  }

  #cleanSection2Id .colCleanProjectsMb li{
   padding-top: 0.8em;
  }

  #cleanSection3Id .rowCleanProjectsMobile{
    display: block;        
  }

  #cleanSection3Id .colTextpt{
    letter-spacing: .8em;   
    
   }
 
   #cleanSection3Id .colTextpt2{
     font-size: 1.5em;  
     letter-spacing: .3em;     
     
    }
  #cleanSection3Id .rowCleanProjectsWeb{
    display: none;        
  }

  #cleanSection3Id .colCleanProjects{
    display: none;
  }

  #cleanSection3Id .colCleanProjectsMb{
    display: block;
    font-size: small;
    line-height: 3;

  }


  #cleanSection4Id .rowCleanProjectsMobile{
    display: block;        
  }

  #cleanSection4Id .colTextpt2{
    font-weight: 400;
    text-align: left;
    font-size: 35px;
    color: #007D14;
    padding-left: 0.5em;
    letter-spacing: 1rem;   
  }

  #cleanSection4Id .rowCleanProjectsWeb{
    display: none;        
  }

  #cleanSection4Id .colCleanProjects{
    display: none;
  }

  #cleanSection4Id .colCleanProjectsMb{
    display: block;
    font-size: small;
    line-height: 3;

  }

  #cleanSection4Id .colTextpt{
    letter-spacing: .5em;   
    
   }
 
   #cleanSection4Id .colTextpt2{
     font-size: 1.5em;  
     letter-spacing: .1em;     
     
    }
    
  #cleanSection4Id .containSection4Mb{
    display: block;
  }
  
  #cleanSection4Id .containSection4{
    display: none;
  }

  #cleanSection4Id .containSection4Mb h6{
   font-size: 0.8em;
  }

  #cleanSection4Id .containSection4Mb img{
    width: 18em;
    height: auto;
    padding-bottom: 3em;
    
   }
 
  
  
  
  #cleanSection5IdMb {
    display: block;
  }

  #cleanSection5IdMb .colSection5{
    font-size: large;
    text-align: center;
    line-height: 1;
    font-weight: 500;
    color: #007D14;
    border-color: #007D14;    
    letter-spacing: .5em; 
    padding-top: 3em;
  }

  #cleanSection5IdMb .colSection5 hr{
    
    border-color: #007D14;     
  }

  #cleanSection5IdMb .colSectionImg5 img{
    padding-top:0.8em;
  }
 #cleanSection5Id {
  display: none;
}

}