#rsaProjects {
    h2{
        color: #B30FCD
    }
    h6{
        color: #720485;
        font-weight: 500;
    }
    p{
        color: #505050;
        font-weight: 800;
        font-size: 0.9em;
        word-break: keep-all;
    }
    .colTitle{
        color: #B30FCD;
    }
       hr{
        border-color: #B30FCD; 
    }
    .colTitle{
        font-weight: 900;
        color: #B30FCD;
        font-size: 1.2em;
    }
}


#rsaCard {

    img{
        width: 100%;
    }
    p{
        word-break: keep-all;
    }
}