#carouselId .carouselBtn {
  padding-left: 1.2em;
  padding-right: 1.2em;
}

#carouselId .videoKstar {
  background-image: url("@/assets/img/FieldIndex/field/size_img/5_1.png");
}

#carouselId .btn {
  margin: -0.25rem;
  color: inherit;
  text-transform: uppercase;
  word-wrap: break-word;
  white-space: normal;
  cursor: pointer;
  border: 0;
  border-radius: 2rem;
  box-shadow: 0 2px 5px 0 rgb(0 0 0 / 16%), 0 2px 10px 0 rgb(0 0 0 / 12%);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  padding: 0.84rem 2.14rem;
  font-size: 0.81rem;
}
