
#continTestCol .rowTest {
    background-color: rgb(226, 168, 43); 

}

#continTestCol .rowTest1{
    background-color: rgb(152, 147, 156);

}

#continTestCol .rowTest2 {
    background-color: rgb(136, 182, 30);
   
}

#continTestCol .rowTest3 {
    background-color: rgb(226, 214, 43);

}

#continTestCol .rowTest4 {
    background-color: rgb(202, 202, 195);
}

#continTestCol .rowTest5 {
    background-color: rgba(39, 97, 223, 0.315);
  
}