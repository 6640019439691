/* mainContainer css */
.mainContainer {
  padding-top: 24px;
}
/* <section id='Ceo_1'> */
#Ceo {
  background-color: rgb(255, 255, 255);
}
#Ceo .topLinkPageMobile {
  display: none;
}
#Ceo .ceoMobile {
  display: none;
}
@media(max-width: 767px) {
  #Ceo .topLinkPageMobile {
      display: block;
  }
  #Ceo .ceoMobile {
      display: block;
  }
  #Ceo .ceoWeb {
      display: none;
  }
  #Ceo .topLinkPageWeb {
      display: none;
  }
}
#Ceo_1 .h-100 {
  background-color: none;
  background-size: cover;
  position: relative;
  top: 20em;
  left: 0;
  text-align: center;
  font-size: 0.3em;
  background-repeat: no-repeat;
}
@media(min-width: 990px) {
  #Ceo_1 .h-100 {
      background-color: none;
      background-size: cover;
      position: relative;
      top: 20em;
      left: 8em;
      text-align: center;
      font-size: 0.3em;
      background-repeat: no-repeat;
  }
}
/* #Ceo_1 .topLogo{
  width: 800px;
  height: 800px;
  background-repeat: no-repeat;
  top: 90px;
  background-image: url('https://tritech.s3.ap-northeast-2.amazonaws.com/homepage_img/topLogo-232-262.png');
  animation-name: r360;
  animation-duration: 10s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  
} */
/* <section id='Ceo_sn'> */
/* #Ceo_sn .topLogo{

} */
#Ceo_sn .display-3 {
  display: flex;
  font-size: 9em;
  color: #ffffff
}
#Ceo_sn .mt-5 {
  display: inline;
  position: relative;
  top: 1em;
  text-align: center;
  text-shadow: 1px 1px 1px rgb(126, 126, 126);
  color: #ffffff;
}
#Ceo2 .cardBodyBg {
  background-color: #F7F8FC;
}
/* <section id="about_tritech"> */
#Ceo2 .aboutUsText {
  font-size: 0.99em;
  font-family: Arial, Helvetica, sans-serif;
  color: rgb(126, 126, 126);
  line-height: 2;
}
#about_tritech {
  padding-top: 20px;
}
#about_tritech .about_top {
  box-shadow: none;
}
#about_tritech .sub_title01 {
  font-size: 35px;
  font-weight: 400;
  line-height: 1.3em;
  text-align: center;
  letter-spacing: 0.40em;
  color: #2370c9;
  margin-bottom: 8px;
  margin-top: 0;
  font-family: 'Goldman', cursive;
}
#about_tritech .sub_title02 {
  font-size: 53px;
  font-weight: 400;
  line-height: 1em;
  text-align: center;
  color: #121212;
  margin-bottom: 15px;
}
#about_tritech .sub_title_img01 {
  text-align: center;
}
#about_tritech .sub_title_img01 img {
  width: 10%;
  height: 100%;
  padding-top: 20px;
}
#about_tritech .sub_text01 {
  font-size: 15px;
  text-align: center;
  line-height: 1.2em;
  color: #2370c9;
  font-weight: bold;
  margin-bottom: 2em;
  margin-top: 1em;
}
#about_tritech .rowTextRd {
  align-items: center;
  justify-content: center;
  margin-bottom: 3em;
  margin-top: 3em;
  font-size: 1rem;
  font-weight: 400;
  color: #969696;
  padding: 0 1em;
  line-height: 3rem;
  word-break: keep-all
}
#Ceo2 .wordBreack {
  word-break: keep-all;
  font-size: 0.9em;
  font-weight: 500;
}
#about_us .wordBreack {
  word-break: keep-all;
}
#about_tritech .rowTopText {
  padding-left: 10rem;
  padding-right: 10rem;
  margin-bottom: 5rem;
}
#about_tritech .sub_text01 p {
  font-size: 17px;
  text-align: center;
  line-height: 1.2em;
  color: #878787;
  font-weight: 400;
  margin-bottom: 40px;
  margin-top: 40px;
}
#about_tritech .view .mb-5,
.my-5 {
  margin-bottom: 1rem !important;
  margin-top: 1rem !important;
}
#Partners .colPartnersImg {
  padding-right: 0;
  padding-left: 0;
}
/* 768 이하  */
@media(max-width: 767px) {
  #about_tritech .sub_title_img01 {
      display: none;
  }
  #about_tritech .sub_text01 {
      font-size: 13px;
      text-align: center;
      line-height: 1.2em;
      color: #2370c9;
      font-weight: bold;
      margin-bottom: 2em;
      margin-top: 1em;
  }
}
#hello .about_top {
  box-shadow: none;
}
#Ceo2 .about_top_mobile {
  display: none;
}
@media(max-width: 992px) {
  #Ceo2 .about_top_mobile {
      display: block;
  }
  #Ceo2 .about_top {
      display: none;
  }
}
/* <section id="about_us"> */
#about_us {
  padding-top: 0;
}
#about_us .card {
  font-weight: 400;
  border: 0;
  box-shadow: none;
}
#about_us .colImgSize img {
  width: 6rem;
}
#about_us .colImgSize2 img {
  width: 5.5rem;
}
#about_us .colImgSize3 img {
  width: 4.5rem;
}
#about_us .about_top {
  box-shadow: none;
}
#about_us .lead {
  margin-top: 1em;
  font-weight: 700;
  font-size: 15em;
}
#about_us .bold-text {
  font-weight: 700;
}
#about_us .aboutUsMain img {
  padding-top: 40px;
}
#about_us .aboutUsText {
  padding-top: 40px;
}
/* 768 이하  */
@media(max-width: 767px) {
  #about_us {
      padding-top: 40px;
  }
  #about_us .aboutUsText {
      padding-top: 1.2em;
  }
  #about_us .lead {
      margin-top: 1em;
      font-weight: 700;
      font-size: 5em;
  }
  #about_us .bold-text {
      font-weight: 700;
  }
}
/* <section id='hello'> */
@media(max-width: 767px) {
  #hello .TitleText {
      padding-top: 10px;
  }
  #Ceo_1 img {
      vertical-align: middle;
      border-style: none;
      width: 150px;
  }
}
#organization .containOrgan {
  background-color: #F7F8FC;
}
#organization .organizationH6 {
  font-weight: 700;
  font-size: 25px;
  /* margin-bottom: 2.5em; */
}
#organization .stepper {
  display: flex;
  justify-content: center;
  align-items: center;
}

#organizationMobile .containOrgan {
  display: none;
}
@media(max-width:727px) {
  #organizationMobile .containOrgan {
      display: block;
      background-color: #F7F8FC;
  }
  #organization .containOrgan {
      display: none;
  }
  #organizationMobile .organizationH6 {
      font-weight: 700;
      font-size: 25px;
      margin-bottom: 2.5em;
  }
}
#Partners .bodyPartners h6 {
  font-weight: 700;
  font-size: 25px;
}
#Partners .bodyPartnersP {
  font-weight: 700;
  font-size: 19px;
}
/* footerContainer css */
.footerBg {
  background-color: #000000;
}
#Ceo #content img {
  width : 90%;
  display: block;
  margin: auto;
  margin-bottom: 100px;
}