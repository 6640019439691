#pageTitleSection {
  justify-content: center;
  text-align: center;
  margin-top: 5rem !important;
  margin-bottom: 3rem;
}
/* --------------------- */
#bar {
  word-break: keep-all;
  margin-top: 2rem;
  margin-bottom: 2rem;
  justify-content: center;
  text-align: center;
}
#bar .line {
  border: 1px solid;
}
#bar .mainContent {
  border-radius: 0.80rem !important;
  padding-top: 3rem;
  padding-bottom: 3rem;
}
/* --------------------- */
/* --------------------- */
#wideImgSection {
  word-break: keep-all;
  padding-top: 4rem;
  padding-bottom: 4rem;
  justify-content: center;
  text-align: center;
}
#wideImgSection .titleContainer {
  /* padding-bottom: 1rem; */
  padding-left: 2rem
}
#wideImgSection .titleIcon {
  padding-bottom: 0.5rem;
}
#wideImgSection .desc {}
#wideImgSection .mainContent {
  border-radius: 0.80rem !important;
  padding-top: 1rem;
  padding-bottom: 1rem;
}
#wideImgSection .lineBreak {
  padding-bottom: 2rem;
}
#wideImgSection .line {
  border: 1px line;
}
#wideImgInGrayBgSection .ul {
  display: inline-block;
  padding-right: 2rem;
}
/* --------------------- */
#wideImgInGrayBgSection {
  background-color: #F5F5F5;
  word-break: keep-all;
  padding-top: 4rem;
  padding-bottom: 4rem;
  justify-content: center;
  text-align: center;
}
#wideImgInGrayBgSection .titleContainer {
  padding-left: 2rem
}
#wideImgInGrayBgSection .titleIcon {
  padding-bottom: 0.5rem;
}
#wideImgInGrayBgSection .titleLeftImg {
  padding-bottom: 1rem;
  padding-right: 2rem;
  justify-content: left;
  text-align: left;
}
#wideImgInGrayBgSection .titleGrayText {
  color: #848484;
  font-size: 1.5em;
  font-weight: 500;
  letter-spacing: 0.4em;
}
#wideImgInGrayBgSection .desc {}
#wideImgInGrayBgSection .mainContent {
  border-radius: 0.80rem !important;
  padding-top: 1rem;
  padding-bottom: 1rem;
}
#wideImgInGrayBgSection .lineBreak {
  padding-bottom: 2rem;
}
#wideImgInGrayBgSection .line {
  border: 1px line;
}
#wideImgInGrayBgSection .ul {
  display: inline-block;
  padding-right: 2rem;
}
/* --------------------- */
/* --------------------- */
/* --------------------- */
#grayBgSection {
  background-color: #F5F5F5;
  margin-top: 5rem !important;
  margin-bottom: 3rem;
}
#netWork {
  justify-content: center;
  text-align: center;
  margin-top: 5rem !important;
  margin-bottom: 3rem;
}
/* //////////// */
hr {
  border: 1px line;
}